import React, { useContext } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "../components/mixins"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import Layout from "../components/Layout/Layout"
import Chip from "@components/Cards/Chip.jsx"
import { Typography, Grid, Avatar, CardMedia } from "@material-ui/core"
import infoLabel from "@static/images/Info.svg"
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import { getStrapiContentByLang } from "@src/heplers.js"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import FloatWrap from "@components/Wrap/FloatWrap.jsx";
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  // <<<<<<<<<<<<<<<< Блок "Медицинские услуги"  >>>>>>>>>>>>>>>>>>>>>
  medical__flex: {
    flexDirection: "column",
    textAlign: "center",
    [breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  medical__padding: {
    paddingBottom: "100px",
    [breakpoints.down("md")]: {
      paddingBottom: "25px",
    },
  },
  // <<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>
  important__padding: {
    paddingBottom: "120px",
    [breakpoints.down("sm")]: {
      paddingBottom: "60px",
    }
  },
  important__header: {
    padding: "30px 0",
    [breakpoints.down("md")]: {
      paddingBottom: "16px",
    },
  },
  important__chip: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "20px",
    lineHeight: "24px",
    width: "180px",
    height: "48px",
    [breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "17px",
      width: "134px",
      height: "40px",
    },
  },
  // <<<<<<<<<<<<<<<< Блок "Медицинская помощь"  >>>>>>>>>>>>>>>>>>>>>
  help__padding: {
    paddingBottom: "150px",
    [breakpoints.down("sm")]: {
      paddingBottom: "0px",
    }
  },
  help__header: {
    fontSize: "22px",
    lineHeight: "26px",
    fontWeight: "500",
    paddingBottom: "36px",
    [breakpoints.down("sm")]: {
      paddingBottom: "12px",
    }
  },
  help__img: {
    display: "block",
    [breakpoints.down("sm")]: {
      display: "none",
    }
  },
  help__linker: {
    display: 'contents',
    color: palette.text.black,
    fontSize: "18px !important",
    lineHeight: "21px !important",
    [breakpoints.down("sm")]: {
      fontSize: "14px !important",
      lineHeight: "17px !important"
    },
  },
  // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
  desktop_text: {
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
  },
  mobile_text: {
    [breakpoints.down("sm")]: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px"
    }
  },
  bold_weight: {
    fontWeight: "500",
  },
})

const Article = ({ Title, Image, Content }) => {

  const {
    // <<<<<<<<<<<<<<<< Блок "Медицинская помощь"  >>>>>>>>>>>>>>>>>>>>>
    help__padding,
    help__header,
    // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
    desktop_text,
    mobile_text,
  } = makeComponentStyles(styles);

  return (
    <>
      <Container mw={"lg"}>
        <Grid container className={help__padding}>
          <Grid item xs={12} md={12}>
            <Typography className={help__header}>
              <Markdown>{Title}</Markdown>
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={5}>
              <CardMedia
                component="img"
                image={Image.localFile.publicURL}
                alt='photo' />
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography className={clsx(desktop_text, mobile_text)}>
                <Markdown>{Content}</Markdown>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const MedicalServices = ({ data }) => {

  const {
    // <<<<<<<<<<<<<<<< Блок "Медицинские услуги"  >>>>>>>>>>>>>>>>>>>>>
    medical__flex,
    // <<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>
    important__padding,
    important__header,
    important__chip,
    // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
    desktop_text,
    mobile_text,
    bold_weight,
  } = makeComponentStyles(styles);

  const { language } = useContext(IntlContextConsumer);
  const Intl = useIntl();
  const {
    Info,
    ImportantInfo,
    InfoImage,
    Articles,
    HospitalsSEO
  } = getStrapiContentByLang(data.allStrapiHospitals.edges, language)

  return (
    <div>
      <Seo title={HospitalsSEO?.TitleSEO}
           description={HospitalsSEO?.DescriptionSEO}
           keywords={HospitalsSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          {/*<<<<<<<<<<<<<<<< Блок "Медицинские услуги"  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                  {Intl.formatMessage({ id: "hospital" })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FloatWrap className={clsx(desktop_text, mobile_text)} Img={InfoImage.localFile.publicURL} Float='right'>
                      <Markdown>{Info}</Markdown>
                    </FloatWrap>
                  </Grid>
                </Grid>
              </MainWrap.Body>
            </MainWrap>
          </Container>
          {/*<<<<<<<<<<<<<<<< Блок важно  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <Grid container className={important__padding}>
              <Grid className={important__header} item xs={12}>
                <Chip avatar={<Avatar src={infoLabel} />}
                      label={Intl.formatMessage({ id: "important" })}
                      straight className={clsx(important__chip, bold_weight)} />
              </Grid>
              <Grid container className={medical__flex} xs={12} md={12}>
                <Typography className={clsx(desktop_text, mobile_text)}>
                  <Markdown>{ImportantInfo}</Markdown>
                </Typography>
              </Grid>
            </Grid>
          </Container>
          {/*<<<<<<<<<<<<<<<< Блок "Медицинская помощь"  >>>>>>>>>>>>>>>>>>>>>*/}
          {
            Articles.map(props => (<Article {...props} />))
          }
        </Layout>
      </>
    </div>
  )
}

export default MedicalServices

export const pageQuery = graphql`{
  allStrapiHospitals {
    edges {
      node {
        Info
        ImportantInfo
        Articles {
          Title
          Image {
            localFile {
              publicURL
            }
          }
          Content
        }
        InfoImage {
          localFile {
            publicURL
          }
        }
        locale
        HospitalsSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
      }
    }
  }
}`;
